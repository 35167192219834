export const BG_PRIMARY = 'rgb(117 104 83)';
export const BG_PRIMARY_GRADIENT = 'rgb(117 104 83 / 50%)';
export const BG_PRIMARY_DARK = 'rgb(92 79 59)';
export const BG_PRIMARY_DARK_GRADIENT = 'rgb(92 79 59 / 80%)';

export const BG_SECONDARY = '#F6F0E7';
export const BG_ALTERNATIVE = 'rgb(117 76 51)';
export const BG_ALTERNATIVE_DARK = '#5c4f3b';
// color text
export const TEXT_PRIMARY = '#fff';
export const TEXT_SECONDARY = '#5B341E';
export const TEXT_ALTERNATIVE = '#756853';
export const TEXT_SHADOW = '#3c1410';
// music & navigation
export const NAVIGATION_COLOR = '#dcd0b2';
export const DRAWER_COLOR = 'rgb(116 84 63)';
export const BLACK_COLOR = '#362A22';
// Frame Color
export const RSVP_BORDER_COLOR = '#ADA69D'; // Akad, Resepsi, RSVP, Gift
export const WEDDING_BORDER_COLOR = '#F6F0E7'; // Welcome, Wishes

// DEFAULT BUTTON PROPS
export const BUTTON_GRADIENT = `linear-gradient(to right, ${BG_PRIMARY_DARK}, ${BG_SECONDARY} 200%)`;
export const BUTTON_PROPS = {
  _active: { borderColor: 'transparent' },
  _hover: { borderColor: 'transparent' },
  _focus: { borderColor: 'transparent' },
};

export const DEFAULT_BUTTON_PROPS = {};